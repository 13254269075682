import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'

import { Container, Row, Col } from 'react-bootstrap'

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/PageTitle"
import Partners from "../components/PartnersSponsors/Partners"
import Sponsors from "../components/PartnersSponsors/Sponsors"

const Results = ({ data }) => (
  <Layout>
    <SEO title="Teton Ridge Classic Race Results" />
    <PageTitle 
      title="Race Results"
    />
    <Container fluid>
      <Row className="my-4">
        <Col sm={10}>
        <Row>
        <Col>
        <h3>Recent Results</h3>
        <p><small className="text-muted">Opens on webscorer.com:</small></p>
        <Row>
          <Col sm={2}>
        <a href="https://www.webscorer.com/seriesresult?seriesid=90242" rel="noopener noreferrer">2017 and newer</a>
          </Col>

        </Row>
        </Col>
      </Row>
      <hr />
      <h3>Historical Results</h3>
      <p><small className="text-muted">Opens as PDF in a new window/tab:</small></p>
      <Row className="">
        <Col sm={3} className="mb-2">
        <h4>2016 Results</h4>
        <p>
          <a href="https://images.ctfassets.net/o5wuoir5sqat/QHbyqEmAFAH8SYKjThmge/4e658401de57cec3065600ad0bdae469/2016-Overall.pdf" target="_blank" rel="noopener noreferrer">Overall Results</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/dPtFkp9lL0deOMDYwFgWb/3f84d94ac21368d5751feef61fb98a50/2016-Overall-gender.pdf" target="_blank" rel="noopener noreferrer">Overall Results By Gender</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/ac6zSAhrFPUdidLCz3KtP/c3f53e571aaf1cf841cc4a14e0bcb5cd/2016-Age-Group.pdf" target="_blank" rel="noopener noreferrer">Age Group Results</a>
        </p>
        </Col>
        <Col sm={3} className="mb-2">
        <h4>2015 Results</h4>
        <p>
          <a href="https://images.ctfassets.net/o5wuoir5sqat/2zbtfP6H3B3s96Ts4fIyqx/aa4403c74b41a73a3fa52d8f53917b70/2015-Overall-Results.pdf" target="_blank" rel="noopener noreferrer">Overall Results</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/7zSK3ASfVakLTJV75FmmcR/45b8b9bea578d59c3be56f433d2fd49a/2015-Overall-Results-by-Gender.pdf" target="_blank" rel="noopener noreferrer">Overall Results By Gender</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/2uKvsj8kXvY9PN6BSR6pP1/83e53d12a13e6e900ffe556d7ef53bc6/2015-Age-Group-Results.pdf" target="_blank" rel="noopener noreferrer">Age Group Results</a>
        </p>
        </Col>
        <Col sm={3} className="mb-2">
        <h4>2014 Results</h4>
        <p>
          <a href="https://images.ctfassets.net/o5wuoir5sqat/5TWOpPcYcox3NbDszYyYob/46ccd977f6efcddbd33e5193adae06d7/2014-Overall-Results.pdf" target="_blank" rel="noopener noreferrer">Overall Results</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/4zGYwrgEBPnJSsl3GOtntK/082aec4e829739db26ac9cf4d99b6488/2014-Overall-Results-by-Gender.pdf" target="_blank" rel="noopener noreferrer">Overall Results By Gender</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/18dabt3jCI92bDIKyjEEwR/8c24796e48eefb98f6d54fc7a2d0f973/2014-Age-Group-Results.pdf" target="_blank" rel="noopener noreferrer">Age Group Results</a>
        </p>
        </Col>
        <Col sm={3} className="mb-2">
        <h4>2013 Results</h4>
        <p>
          <a href="https://images.ctfassets.net/o5wuoir5sqat/2BfS5XHI43R78qjbymAyXH/adee600fd27d9c08732b89b6f38aafae/2013-Overall-Results.pdf" target="_blank" rel="noopener noreferrer">Overall Results</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/7bw5Pbb525rO5XBnQkMIoQ/6456a0e4295d4e71a3d04722090e7b63/2013-Overall-Results-by-Gender.pdf" target="_blank" rel="noopener noreferrer">Overall Results By Gender</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/JGWrraCAXwcpjEpvZkHKI/df6cc0dd39948232b8f1759833c19754/2013-Age-Group-Results.pdf" target="_blank" rel="noopener noreferrer">Age Group Results</a>
        </p>
        </Col>
      </Row>
      <Row className="">
        <Col sm={3} className="mb-2">
        <h4>2012 Results</h4>
        <p>
          <a href="https://images.ctfassets.net/o5wuoir5sqat/4HwwfrmJRJuvtS1sTl7ls5/8e08a51231af03d91db884507deec843/2012-Overall-Results.pdf" target="_blank" rel="noopener noreferrer">Overall Results</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/3Exmcg04D87fam5eJ6CrTp/0989267cd9980ea740158d6124acb543/2012-Overall-Results-by-Gender.pdf" target="_blank" rel="noopener noreferrer">Overall Results By Gender</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/1G58x7kW7C4vTUi8KQdIrw/86f70f79bcf9d3e6aac0cb674bbe6586/2012-Age-Group-Results.pdf" target="_blank" rel="noopener noreferrer">Age Group Results</a>
        </p>
        </Col>
        <Col sm={3} className="mb-2">
        <h4>2011 Results</h4>
        <p>
          <a href="https://images.ctfassets.net/o5wuoir5sqat/4aIYhydUcjC0j65Sz1dFdc/48fc0851964b3df07b027b54681b9eec/2011-Overall-Results.pdf" target="_blank" rel="noopener noreferrer">Overall Results</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/4jcpAOSAUZNGatmoRjeqgH/6a4f83ad0917355efd517daad8c8a30d/2011-Age-Group-Results.pdf" target="_blank" rel="noopener noreferrer">Age Group Results</a>
        </p>
        </Col>
        <Col sm={3} className="mb-2">
        <h4>2010 Results</h4>
        <p>
          <a href="https://images.ctfassets.net/o5wuoir5sqat/2WfMb5J1Q361sS7h107AGr/172ac09feb3dda34848d2d998cf7ad22/2010-Overall-Results.pdf" target="_blank" rel="noopener noreferrer">Overall Results</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/44MdKPnMgBB45aEO62YXPa/a96051514f96882366fb69d1105fa906/2010-Age-Group-Results.pdf" target="_blank" rel="noopener noreferrer">Age Group Results</a>
        </p>
        </Col>
        <Col sm={3} className="mb-2">
        <h4>2009 Results</h4>
        <p>
          <a href="https://images.ctfassets.net/o5wuoir5sqat/7wFla6jvqPqZNnhanK78X6/0668f831b7a9cebe4d52274bf8a84835/2009-Overall-Results.pdf" target="_blank" rel="noopener noreferrer">Overall Results</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/1Yr8cS2GBbANxGz7OiS4cj/e7da6afac7d9cab7711fcd90a27cfce1/2009-Age-Group-Results.pdf" target="_blank" rel="noopener noreferrer">Age Group Results</a>
        </p>
        </Col>
      </Row>
      <Row className="">
        <Col sm={3} className="mb-2">
        <h4>2008 Results</h4>
        <p>
          <a href="https://images.ctfassets.net/o5wuoir5sqat/7y2wfBJM8UQXdSyXZUYH5S/4400b384037617793b291710e0c44d1d/2008-Overall-Results.pdf" target="_blank" rel="noopener noreferrer">Overall Results</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/3yfIV0tegMz9Q6ai0JY2kD/f2e2dde1d6621b3d26f5dd8a9053f470/2008-Age-Group-Results.pdf" target="_blank" rel="noopener noreferrer">Age Group Results</a>
        </p>
        </Col>
        <Col sm={3} className="mb-2">
        <h4>2007 Results</h4>
        <p>
          <a href="https://images.ctfassets.net/o5wuoir5sqat/5fsVj5F9ClxNgjZEBL4xV7/a087340ab897a2a90fc6ffa0b4c4d1a8/2007-Overall-Results.pdf" target="_blank" rel="noopener noreferrer">Overall Results</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/6v6T0tzsjE8xNGM2oeFZXS/1418432dbfc503d4cf13b64faef8209a/2007-Age-Group-Results.pdf" target="_blank" rel="noopener noreferrer">Age Group Results</a>
        </p>
        </Col>
        <Col sm={3} className="mb-2">
        <h4>2006 Results</h4>
        <p>
          <a href="https://images.ctfassets.net/o5wuoir5sqat/1jJbrfWxk5rTKlLAtKJiOI/5b6cc501f8b753ee9e67f3207eb204a8/2006-Overall-Results.pdf" target="_blank" rel="noopener noreferrer">Overall Results</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/4DQLtPwHjR99ORRsRexZVI/25823aa3e36cc41b5662530998b53db2/2006-Age-Group-Results.pdf" target="_blank" rel="noopener noreferrer">Age Group Results</a>
        </p>
        </Col>
        <Col sm={3} className="mb-2">
        <h4>2005 Results</h4>
        <p>
          <a href="https://images.ctfassets.net/o5wuoir5sqat/3XEEPofCYOhtZxXFcI4efG/e1f710c36c4daa59727276b63891deb4/2005-Overall-Results.pdf" target="_blank" rel="noopener noreferrer">Overall Results</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/4hEgsqln9U9SGyXiPzDwfL/7c49f1c905d19397a55dbb66fae30a1c/2005-Age-Group-Results.pdf" target="_blank" rel="noopener noreferrer">Age Group Results</a>
        </p>
        </Col>
      </Row>
      <Row className="">
        <Col sm={3} className="mb-2">
        <h4>2004 Results</h4>
        <p>
          <a href="https://images.ctfassets.net/o5wuoir5sqat/5rsZBCCOOa5Uzm63dtEOsQ/51f872b9a8bd0bcc6259775bf478389c/2004-Overall-Results.pdf" target="_blank" rel="noopener noreferrer">Overall Results</a><br />
          <a href="https://images.ctfassets.net/o5wuoir5sqat/7pZUVWJi3X41M7TOmxkqUp/d17b5f00fb58063a266428eb0a7ac7ba/2004-Age-Group-Results.pdf" target="_blank" rel="noopener noreferrer">Age Group Results</a>
        </p>
        </Col>
      </Row>
        </Col>
        
        <Col sm={2}>
          <Img fluid={data.winners.childImageSharp.fluid} alt="Teton Ridge Classic race winners" className="rounded" />
        </Col>
      </Row>
       </Container>
    <Container fluid>
    <hr />
        <Row>
              <Partners />
            </Row>
            <hr />
            <Row>
              <Sponsors />
            </Row>
            </Container>
  </Layout>
)

export const query = graphql`
  query {
    winners: file(relativePath: { eq: "photo_winners.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
}`

export default Results
