import React from 'react'
import { Container } from 'react-bootstrap'
import TetonsPage from '../../images/TetonsPage'

const PageTitle = (props) => (
    <Container fluid style={{
        padding: 0
    }}>
    <div style={{
      backgroundColor: '#85b7fe',
      height: '125px',
      display: 'flex',
      alignItems: 'flex-end'
    }}>
      <div className="teton-overlay">
          <div>
            <TetonsPage />
          </div>
        </div>
        <div className="pageTitleContainer" style={{zIndex: 1000}}><h1 className="pageTitle">{props.title}</h1></div>
    </div>
    </Container>
)

export default PageTitle